function SportsData() {

    return (
      <div className="bg-black-red-900 p-6">
        <div className="flex items-center justify-center">
          <a href="https://sportsdata.io" target="_blank" rel={"noreferrer"}>
            <img src="https://sportsdata.io/assets/images/badges/sportsdataio_light_100.png?v=1" alt="Sports Data Provider" />
          </a>
        </div>
      </div>
);
}

export default SportsData;
